import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, FormGroup, Label } from "reactstrap";
import PhoneInput, {
    parsePhoneNumber,
    getCountryCallingCode,
    isValidPhoneNumber
} from "react-phone-number-input";
import toast from "react-hot-toast";

import { AutoComplete } from "components/AutoComplete";
import { CustomModal } from "components/CustomModal";
import { TextInput } from "components/Input";
import LeftArrowIcon from "assets/images/customer/svg/LeftArrowIcon";
import { CustomButton } from "components/CustomButton";
import { GetActiveCountries, UpdateDeliveryInfo } from "api/api.service";

function TMCRApplicationForm(props) {
    const { isOpen, handleClose, registrationId, formData } = { ...props };
    const [countries, setCountries] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getActiveCountries();
    }, []);

    // Fetching active countries from API
    const getActiveCountries = async () => {
        const res = await GetActiveCountries();
        const validKeys = ["name", "id"];
        const processedCountries = res?.data.map((country) => {
            Object.keys(country).forEach((key) => validKeys.includes(key) || delete country[key]);
            return {
                ...country,
                label: country.name,
                value: country.name,
                countryId: country.id
            };
        });
        setCountries(processedCountries);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            customerName: formData?.customerName || "",

            city: formData?.city || "",
            streetName: formData?.streetName || "",
            mailBox: formData?.mailBox || "",
            zipCode: formData?.zipCode || "",

            phoneNumber: `${formData?.countryCode}${formData?.phoneNumber}` || "",
            address: formData?.address || "",
            countryId: formData?.country
                ? {
                      label: formData?.country?.name,
                      value: formData?.country?.name,
                      countryId: formData?.country?.id
                  }
                : null
        },
        validationSchema: Yup.object({
            customerName: Yup.string().required("Enter Your Name"),

            city: Yup.string().required("Enter Your City"),
            streetName: Yup.string().required("Enter Your Street Name"),
            mailBox: Yup.string().required("Enter Your Mailbox"),
            zipCode: Yup.string()
                .matches(/^\d+$/, "Enter a valid Zip Code") // Ensures zip code contains only digits
                .required("Enter Your Zip Code"),
            phoneNumber: Yup.string()
                .required("Enter Phone Number")
                .test("phoneNumber", "Phone number is invalid", function (val) {
                    return val && isValidPhoneNumber(val);
                }),
            address: Yup.string().required("Enter Your Address")
        }),
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = async (initialValues) => {
        const { phoneNumber, countryId } = initialValues;

        let countryCodeAlpha = parsePhoneNumber(phoneNumber).country;
        let countryCode = getCountryCallingCode(countryCodeAlpha);
        countryCode = `+${countryCode}`;
        let phone = phoneNumber.split(countryCode)[1];

        let formValues = { ...initialValues };

        formValues.phoneNumber = phone;
        formValues.countryCodeAlpha = countryCodeAlpha;
        formValues.countryCode = countryCode;

        // Extract countryId as a number instead of the full object
        formValues.countryId = countryId?.countryId || countryId;

        try {
            setIsLoading(true);
            let res = await UpdateDeliveryInfo(Number(registrationId), {
                customerName: formValues.customerName,
                phoneNumber: formValues.phoneNumber,
                countryCodeAlpha: formValues.countryCodeAlpha,
                countryCode: formValues.countryCode,
                countryId: formValues.countryId, // Ensure this is a number
                address: formValues.address,
                city: formValues?.city || "",
                streetName: formValues?.streetName || "",
                mailBox: formValues?.mailBox || "",
                zipCode: formValues?.zipCode || ""
            });
            if (res?.message == "Updated") {
                setIsLoading(false);
                toast.success("Delivery information has been updated!");
            }
        } finally {
            setTimeout(() => {
                handleClose();
            }, 1200);
            setIsLoading(false);
        }
    };

    return (
        <CustomModal
            isOpen={isOpen}
            size="lg"
            data={
                <div className="p-4 modal-detail">
                    <div className="d-flex align-items-center">
                        <button className="border-0  p-0  bg-white" onClick={handleClose}>
                            <LeftArrowIcon />
                        </button>
                        <h2 className="m-0 px-2">Get Physical Original TMCR</h2>
                    </div>
                    <div className="p-4" style={{ paddingLeft: "40px !important" }}>
                        <h6>Request to send original TMCR express mail</h6>
                        <p>
                            If you want to receive an original TMCR mail, please fill out the form
                            below.
                        </p>
                        <Form
                            onSubmit={formik.handleSubmit}
                            className="py-4 pl-6 trademark-registration-modal"
                        >
                            <FormGroup>
                                <Label for="customerName">Full Name</Label>
                                <TextInput
                                    placeholder="John Smith"
                                    name="customerName"
                                    type="text"
                                    value={formik.values.customerName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="hide-icon w-full"
                                />
                                {formik.touched.customerName && formik.errors.customerName ? (
                                    <span className="error">{formik.errors.customerName}</span>
                                ) : null}
                            </FormGroup>
                            <FormGroup>
                                <Label for="phoneNumber">Phone</Label>
                                <PhoneInput
                                    defaultCountry="SA"
                                    countryOptionsOrder={["SA", "AE", "OM", "BH", "KW", "QA"]}
                                    value={formik.values.phoneNumber}
                                    onChange={(value) => formik.setFieldValue("phoneNumber", value)}
                                    onBlur={() => formik.setFieldTouched("phoneNumber")}
                                    placeholder="Phone Number"
                                    className="phone-num-con"
                                />
                                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                    <span className="error">{formik.errors.phoneNumber}</span>
                                ) : null}
                            </FormGroup>
                            <FormGroup>
                                <Label for="countryId">Country</Label>
                                <AutoComplete
                                    options={countries}
                                    value={formik.values.countryId}
                                    onChange={(val) => formik.setFieldValue("countryId", val)}
                                    isSearchable
                                    placeholder="Select Country"
                                    classNamePrefix="status-header-search-admin w-40"
                                />
                                {formik.touched.countryId && formik.errors.countryId ? (
                                    <span className="error">{formik.errors.countryId}</span>
                                ) : null}
                            </FormGroup>
                            {/* new fields */}
                            <FormGroup>
                                <Label for="city">City</Label>
                                <TextInput
                                    placeholder="Type here"
                                    name="city"
                                    type="text"
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="hide-icon w-full"
                                />
                                {formik.touched.city && formik.errors.city ? (
                                    <span className="error">{formik.errors.city}</span>
                                ) : null}
                            </FormGroup>
                            <FormGroup>
                                <Label for="streetName">Street Name</Label>
                                <TextInput
                                    placeholder="Type here"
                                    name="streetName"
                                    type="text"
                                    value={formik.values.streetName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="hide-icon w-full"
                                />
                                {formik.touched.streetName && formik.errors.streetName ? (
                                    <span className="error">{formik.errors.streetName}</span>
                                ) : null}
                            </FormGroup>{" "}
                            <FormGroup>
                                <Label for="zipCode">Zip Code</Label>
                                <TextInput
                                    placeholder="Type here"
                                    name="zipCode"
                                    type="text"
                                    value={formik.values.zipCode}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="hide-icon w-full"
                                />
                                {formik.touched.zipCode && formik.errors.zipCode ? (
                                    <span className="error">{formik.errors.zipCode}</span>
                                ) : null}
                            </FormGroup>{" "}
                            <FormGroup>
                                <Label for="mailBox">Mailbox</Label>
                                <TextInput
                                    placeholder="Type here"
                                    name="mailBox"
                                    type="text"
                                    value={formik.values.mailBox}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="hide-icon w-full"
                                />
                                {formik.touched.mailBox && formik.errors.mailBox ? (
                                    <span className="error">{formik.errors.mailBox}</span>
                                ) : null}
                            </FormGroup>
                            {/* new fields */}
                            <FormGroup>
                                <Label for="address">Address</Label>
                                <TextInput
                                    placeholder="Address"
                                    name="address"
                                    type="text"
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="hide-icon w-full"
                                />
                                {formik.touched.address && formik.errors.address ? (
                                    <span className="error">{formik.errors.address}</span>
                                ) : null}
                            </FormGroup>
                            <FormGroup>
                                <CustomButton
                                    color="primary"
                                    title="Save Details"
                                    type="submit"
                                    loading={isLoading}
                                    className="w-100 bv-btn-sm mb-3"
                                />
                            </FormGroup>
                        </Form>
                    </div>
                </div>
            }
        />
    );
}

export default TMCRApplicationForm;
