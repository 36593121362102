import React from "react";
import PropTypes from "prop-types";

import { CustomModal } from "components/CustomModal";
import { CustomButton } from "components/CustomButton";

import checkIcon from "assets/images/aiAPaiT/check-icon.png";

const SuccessModalCustom = ({
    btnTitle = "",
    mainContent = "",
    modalActivator = false,
    modalCloseHandler = () => {}
}) => {
    return (
        <>
            <CustomModal
                isOpen={modalActivator}
                className="success-custom-modal"
                data={
                    <div className="text-center success-modal px-5 pb-4 mb-3">
                        <img src={checkIcon} alt="check icon" className="success-modal-icon" />
                        <p className="my-4 ">{mainContent}</p>
                        <CustomButton
                            title={btnTitle}
                            className="w-100"
                            color="primary"
                            onClick={modalCloseHandler}
                        />
                    </div>
                }
            />
        </>
    );
};

SuccessModalCustom.propTypes = {
    btnTitle: PropTypes.string,
    mainContent: PropTypes.string,
    modalActivator: PropTypes.bool,
    modalCloseHandler: PropTypes.func
};

export default SuccessModalCustom;
