import { CLEAR_FORM_DATA, SET_FORM_DATA } from "./actionTypes";

export const setFormsData = (data) => ({
    type: SET_FORM_DATA,
    payload: data
});

export const clearFormData = () => ({
    type: CLEAR_FORM_DATA
});
