import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import SendIcon from "../../../assets/images/aiAPaiT/chat/send-icon.svg";
import { Form, Progress, UncontrolledTooltip } from "reactstrap";
import { ImageTypes } from "../../../constants";
import toast from "react-hot-toast";
import { finalizeMediaUtil, initializeMediaUtil, toBase64, uploadOnS3 } from "utils/mediaUtils";
import PaperClipIcon from "../../../assets/images/paperclip.png";
import { removeSpaceUnderscoreCharacterFromString } from "utils/commonUtils";
import TradeMarkLogo from "../../../assets/images/aiAPaiT/case-mangement/trademark.png";

const KEY = "Enter";
const ESCAPE_CHARACTER = "\n";

const SendMessage = ({ onMessageSent }) => {
    const ref = useRef();
    const [message, setMessage] = useState("");
    const [file, setFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [progress, setProgress] = useState(0);
    const [sending, setSending] = useState(false);

    const handleSubmit = async (e) => {
        e?.preventDefault();

        // Prevent multiple submissions
        if (sending) return;
        setSending(true);

        try {
            if ((message && message.trim().length !== 0) || file) {
                let media;
                if (file) {
                    media = await uploadMedia(file);
                }
                onMessageSent({ messageContent: message, media });
                setMessage("");
                setFile(null);
                setImagePreview(null);
            }
        } finally {
            setSending(false); // Allow submissions again
        }
    };

    const handleEnterKeyPress = (e) => {
        if (
            (e.altKey && e.shiftKey) ||
            (e.ctrlKey && e.key === KEY) ||
            (e.shiftKey && e.key === KEY)
        ) {
            setMessage((prevText) => prevText + ESCAPE_CHARACTER);
            e.preventDefault();
        }
        if (e.key === KEY && !e.shiftKey && !e.ctrlKey) {
            e.preventDefault();
            handleSubmit();
        }
    };

    const handleImageRemove = () => {
        ref.current.value = "";
    };
    const handleFileChange = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        let fileType = file.type.split("/").pop().toLowerCase();
        if (!ImageTypes.includes(fileType)) {
            toast.error("File Type is not supported! kindly upload image/pdf. ");
            handleImageRemove();
            return;
        }
        toBase64(file, (base64) => {
            setImagePreview(base64);
        });
        let fileName = removeSpaceUnderscoreCharacterFromString(file?.name);
        let image = file;
        image.fileName = fileName;
        setFile(image);
        handleImageRemove();
    };

    const uploadMedia = async (file) => {
        // media upload on server
        setProgress(1);
        return initializeMediaUtil(file).then(async (res) => {
            const credentials = res;
            await uploadOnS3(file, credentials, (progress) => {
                setProgress(progress);
            });
            let finalizeRes = await finalizeMediaUtil(credentials?.mediaId);
            setProgress(0);
            return finalizeRes;
        });
    };
    return (
        <>
            <div className="">
                {file && imagePreview && (
                    <div className="chat-image-preview-container">
                        <div className="img-container container-height position-relative mb-0">
                            {file.type.split("/").pop().toLowerCase() !== "pdf" ? (
                                <img
                                    className={progress > 0 ? "blur" : ""}
                                    src={imagePreview}
                                    alt="img"
                                />
                            ) : (
                                <>
                                    <div className="bg-white pdf-img-con">
                                        <img
                                            src={TradeMarkLogo}
                                            alt="trademark"
                                            className="pdf-dummy-img"
                                        />
                                        <span>{file?.name}</span>
                                    </div>
                                </>
                            )}
                            {progress > 0 && (
                                <div className="chat-image-progress">
                                    <Progress color="primary" value={progress}>
                                        {progress}%
                                    </Progress>
                                </div>
                            )}
                            {!progress && (
                                <div
                                    className="position-absolute remove-btn hide remove-file-btn"
                                    onClick={() => {
                                        setImagePreview(null);
                                        setFile(null);
                                    }}
                                >
                                    <i role="button" className="mdi mdi-close font-size-24"></i>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <Form onSubmit={handleSubmit}>
                    <div className="row field form px-0 py-2 text-center">
                        <div className=" message-field">
                            <div className="text-area-con-chat">
                                <textarea
                                    placeholder="Write your message here"
                                    color="primary"
                                    rows="1"
                                    onChange={(e) => setMessage(e.target.value)}
                                    value={message}
                                    onKeyDown={handleEnterKeyPress}
                                ></textarea>
                            </div>
                            <div className="chat-attachment-con position-relative">
                                <label htmlFor="Imagetooltip" className="mb-0 ">
                                    <img src={PaperClipIcon} />
                                </label>
                                <input
                                    ref={ref}
                                    onChange={handleFileChange}
                                    accept=".jpg,.jpeg,.png,.pdf"
                                    type="file"
                                    className="w-100 h-100"
                                    id="Imagetooltip"
                                />
                                <UncontrolledTooltip placement="top" target="Imagetooltip">
                                    {"Images"}
                                </UncontrolledTooltip>
                            </div>
                            <div className="message-field-action">
                                <button className="pa-0 send-msg-btn" type="submit">
                                    Send
                                    <img src={SendIcon} alt="icon" className="ms-2" />
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    );
};

// handling prop types
SendMessage.propTypes = {
    socket: PropTypes.any,
    onMessageSent: PropTypes.any
};

export default SendMessage;
