import { CLEAR_FORM_DATA, SET_FORM_DATA } from "../actions/actionTypes";

const initialState = {
    steps: {}
};

const formDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FORM_DATA:
            return {
                ...state,
                steps: {
                    ...state.steps,
                    ...action.payload // Merge payload data for dynamic updates
                }
            };
        case CLEAR_FORM_DATA:
            return initialState;
        default:
            return state;
    }
};

export default formDataReducer;
