import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Col, Form, FormGroup, Input, Row } from "reactstrap";
import { ImageUpload } from "../../../components/ImageUpload";
import { CustomButton } from "components/CustomButton";
import {
    customStyles,
    countriesOptions,
    shippingActive,
    ImageTypes,
    CardTypes
} from "../../../constants";
import { AutoComplete } from "../../../components/AutoComplete";

import CheckIcon from "assets/images/customer/svg/CheckIcon";
import TrademarkDetail from "pages/CaseManagement/TrademarkDetail";
import DocumentViewerModal from "pages/CaseManagement/DocumentViewerModal";
import TradeMarkLogo from "../../../assets/images/aiAPaiT/case-mangement/trademark.png";
import shortLogo from "assets/images/customer/short-logo.png";

import { CUSTOMER_ROUTES } from "helpers/routeHelpers";
import {
    GetTrademarkById,
    TrademarkLegalisationDocuments,
    TrademarkPaymentId,
    TrademarkSignedDocument
} from "api/api.service";
import {
    documentTypePayload,
    fieldRequirementMessage,
    responseMessages
} from "pages/CaseManagement/Constants";
import { finalizeMediaUtil, initializeMediaUtil, uploadOnS3 } from "utils/mediaUtils";
import { removeSpaceUnderscoreCharacterFromString } from "utils/commonUtils";
import toast from "react-hot-toast";
import AlertModal from "../common/AlertModal/AlertModal";
import SuccessIcon from "assets/images/customer/svg/SuccessIcon";
import CrossIcon from "assets/images/customer/svg/CrossIcon";
import { CustomModal } from "components/CustomModal";
import TrademarkPaymentModal from "./TrademarkPaymentModal";
import LeftArrowIcon from "assets/images/customer/svg/LeftArrowIcon";
import TermsConditionModal from "./TermsConditionModal";
import PaymentModal from "./PaymentModal";
import { ConfirmationModal } from "../common/ConfirmationModal";
import { DOCUSIGN_EVENT_QUERY_PARAMS } from "constants/DocuSignEvents";
import { STEPS_NAME } from "constants/StepsName";
import { DOCUMENT_TYPES } from "constants/DocumentType";
import { useURLQuery } from "hooks/useURLQuery";
import { CASE_STATUS } from "constants/CaseStatus";
import { setFormsData } from "store/actions/trademark/actions/actions";

const regex = /(.+)\((.+)\)\((.+)\)/;
const KB_CONVERTER = 1024;
let dataObj = {
    acceptTrademarkApplication: false
};

const TrademarkSignatureForm = () => {
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { event } = useURLQuery("event");

    const step2Data = useSelector((state) => state.formData.steps.step2);

    let SIGN_COMPLETED = Boolean(event == DOCUSIGN_EVENT_QUERY_PARAMS.SigningComplete);
    let SIGN_DECLINED = Boolean(event == DOCUSIGN_EVENT_QUERY_PARAMS.Decline);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPaymentMethodModal, setIsPaymentMethodModal] = useState(false);
    const [paymentId, setPaymentId] = useState();
    const [isPaymentCardSelected, setIsPaymentCardSelected] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [isFillingLoader, setIsFillingLoader] = useState(false);

    const [trademarkDetails, setTrademarkDetails] = useState(false);
    const [isPdfViewerModalActive, setIsPdfViewerModalActive] = useState(false);
    const [pdfDataLink, setPdfDataLink] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessModal, setIsSuccessModal] = useState(false);
    const [isTermConditionModal, setIsTermConditionModal] = useState(false);
    const [isTrademarkCancelled, setIsTrademarkedCancelled] = useState(false);
    const [isChecked, setIsChecked] = useState(step2Data?.isChecked);
    const [showError, setShowError] = useState(false);
    const [eSign, setESign] = useState(false);
    const [isTrademarkApplicationChecked, setIsTrademarkApplicationChecked] = useState(false);

    const [POAImage, setPOAImage] = useState({
        image: "",
        fileName: undefined,
        imageError: undefined
    });
    const [errorType, setErrorType] = useState(0);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...dataObj,
            acceptTrademarkApplication: step2Data?.isAcceptTrademarkApplicationChecked || false
        },
        validationSchema: Yup.object({
            acceptTrademarkApplication: Yup.bool().oneOf(
                [true],
                "Please accept the trademark application"
            )
        }),
        onSubmit: async (values, { resetForm }) => {
            if (!isChecked) {
                return setShowError(true);
            }
            await handleFormSubmit(resetForm);
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            const res = await GetTrademarkById(Number(id));

            if (res) {
                localStorage.setItem("trademarkId", Number(id));
                setTrademarkDetails(res);

                // Check if the document is signed based on the updated trademark details
                const isSigned = res?.pOAApplication?.documents.some(
                    (el) => el?.documentType == DOCUMENT_TYPES.PowerOfAttorney && el?.isSigned
                );
                setESign(isSigned); // Update eSign based on new data
            }
        };
        // Initial fetch
        fetchData();
    }, []);

    // Another useEffect to handle `trademarkDetails` and `event`
    useEffect(() => {
        const isSigned = trademarkDetails?.pOAApplication?.documents.some(
            (el) => el?.documentType == DOCUMENT_TYPES.PowerOfAttorney && el?.isSigned
        );
        setESign(isSigned);
        // Trigger event update logic
        isDocumentUpdated();
    }, [SIGN_COMPLETED, SIGN_DECLINED]);

    const isDocumentUpdated = async () => {
        // Mark the function as async
        if (
            event == DOCUSIGN_EVENT_QUERY_PARAMS.SigningComplete ||
            event == DOCUSIGN_EVENT_QUERY_PARAMS.ViewingComplete
        ) {
            const powerOfAttorneyDocuments = trademarkDetails?.pOAApplication?.documents.filter(
                (doc) => doc.documentType == DOCUMENT_TYPES.PowerOfAttorney
            );

            if (powerOfAttorneyDocuments?.length > 0) {
                const docID = powerOfAttorneyDocuments[0]; // Assuming we want the first document
                await TrademarkSignedDocument(docID?.id, STEPS_NAME.E_Signature);

                // Call the GetTrademarkById API again after updating
                const updatedRes = await GetTrademarkById(Number(id));
                setTrademarkDetails(updatedRes); // Update state with new data
            } else {
                console.warn("No PowerOfAttorney documents found.");
            }
        } else if (event == DOCUSIGN_EVENT_QUERY_PARAMS.Decline) {
            const powerOfAttorneyDocuments = trademarkDetails?.pOAApplication?.documents.filter(
                (doc) => doc.documentType == DOCUMENT_TYPES.PowerOfAttorney
            );

            if (powerOfAttorneyDocuments?.length > 0) {
                const docID = powerOfAttorneyDocuments[0]; // Assuming we want the first document
                let res = await TrademarkSignedDocument(
                    docID?.id,
                    STEPS_NAME.E_Signature,
                    CASE_STATUS.Close,
                    Number(id)
                );
                if (res?.message == "Updated") {
                    toast.error("Case has been closed.");
                    history.push(CUSTOMER_ROUTES.TRADEMARK);
                }
            } else {
                console.warn("No PowerOfAttorney documents found.");
            }
        }
    };
    let isLegalisation = Boolean(trademarkDetails?.pOAApplication?.legalisation);

    const handleFormSubmit = async (resetForm) => {
        try {
            setIsLoading(true);

            // Check if the document is signed based on the updated trademark details
            const trademarkApplication = trademarkDetails?.pOAApplication?.documents?.filter(
                (el) => el?.documentType == DOCUMENT_TYPES.Application
            );

            // Call TrademarkSignedDocument on form submission
            const res = await TrademarkSignedDocument(
                trademarkApplication[0]?.id,
                STEPS_NAME.E_Signature
            );

            if (res?.message == "Updated") {
                // If legalisation is true, handle media upload
                if (trademarkDetails?.pOAApplication?.legalisation) {
                    if (!POAImage.image) {
                        // Early return when image is missing
                        setErrorType(0);
                        return toast.error("Please upload Power of Attorney Form");
                    }

                    await processLegalisationDocuments(resetForm);
                } else {
                    // Case when legalisation is false and checkbox is checked
                    if (!isChecked) {
                        return setShowError(true); // Early return
                    }

                    setShowError(false);
                    setIsModalOpen(true); // Open modal directly
                }

                setIsTrademarkApplicationChecked(formik.values.acceptTrademarkApplication);
            }
        } catch (error) {
            console.error("Error processing documents:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Helper function to process legalisation documents
    const processLegalisationDocuments = async (resetForm) => {
        const payLoadObj = {
            documents: []
        };

        const imageRes = await handleMediaUpload(POAImage.image);
        payLoadObj.documents.push({
            documentType: documentTypePayload.TRADEMARK_POWER_ATTORNEY,
            mediaId: imageRes?.id
        });

        await TrademarkLegalisationDocuments(payLoadObj);
        resetForm();
        setIsModalOpen(true);
    };

    const tradeFillingHandler = async () => {
        setIsFillingLoader(true);

        try {
            let paymentRes = await TrademarkPaymentId(
                trademarkDetails?.pOAApplication?.id,
                2,
                selectedCard
            );

            dispatch(
                setFormsData({
                    step2: {
                        isAcceptTrademarkApplicationChecked: isTrademarkApplicationChecked,
                        isChecked,
                        caseId: trademarkDetails?.caseId
                    }
                })
            );

            setPaymentId(paymentRes?.paymentId);
            setIsPaymentMethodModal(true);
            setIsPaymentCardSelected(false);
        } finally {
            setIsFillingLoader(false);
        }
    };

    // file uploading
    const FileUploadHander = (file) => {
        let imageError = undefined;
        let fileName = undefined;
        let image = "";
        if (file) {
            const uploadedFileType = file?.name
                ?.split(".")
                [file?.name?.split(".").length - 1]?.toLowerCase();
            let fileSize = file && file?.size;
            fileSize = fileSize / KB_CONVERTER;
            if (!ImageTypes.includes(uploadedFileType)) {
                imageError = responseMessages.FILE_TYPE_ERROR;
                fileName = undefined;
                image = "";
            } else {
                fileName = removeSpaceUnderscoreCharacterFromString(file?.name);
                imageError = "";
                image = file;
                image.fileName = fileName;
            }
        }
        return { imageError, image, fileName };
    };

    const handleFileChange = (e, imageObjUpdater, type) => {
        e.preventDefault();
        let file = e.target.files[0];
        const { image, imageError, fileName } = FileUploadHander(file);
        imageObjUpdater({ image, imageError, fileName });
        if (errorType === 2) {
            type == documentTypePayload.TRADEMARK_POWER_ATTORNEY && !POAImage.image
                ? setErrorType(1)
                : type == documentTypePayload.TRADEMARK_POWER_ATTORNEY &&
                  POAImage.image &&
                  setErrorType(0);
        }
    };

    // hanlde remove image
    const removeImage = (imageObjUpdater) => {
        imageObjUpdater({ image: "", imageError: undefined, fileName: undefined });
    };

    // media upload on server
    const handleMediaUpload = async (file) => {
        return initializeMediaUtil(file).then(async (res) => {
            const credentials = res;
            await uploadOnS3(file, credentials);
            return await finalizeMediaUtil(credentials?.mediaId);
        });
    };

    // PDF modal view fns
    // pdf/image document view handling
    const documentViewerHandler = (dataUrl) => {
        setPdfDataLink(dataUrl);
        setIsPdfViewerModalActive(true);
    };

    // pdf/image document view modal close handler
    const pdfViewerModalCloseHandler = (e) => {
        setPdfDataLink("");
        setIsPdfViewerModalActive(false);
    };
    const handleTermsConditions = () => {
        setIsTermConditionModal(true);
    };

    const handleCardSelect = (cardType) => {
        setSelectedCard(cardType);
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        if (e.target.checked) {
            setShowError(false); // Hide error if checkbox is checked
        } else {
            setShowError(true);
        }
    };

    const handleCancel = () => {
        setShowError(false);
        setIsTrademarkedCancelled(false);
    };

    const handleCancelTrademarkRegistration = (e) => {
        e.preventDefault();
        setIsTrademarkedCancelled(true);
    };

    return (
        <React.Fragment>
            <h6
                style={{
                    fontSize: "35px",
                    color: "#7e5fe5",
                    fontWeight: "600"
                }}
            >
                {"E Signatures"}
            </h6>
            <Form onSubmit={formik.handleSubmit} className="e-sign-form">
                <FormGroup>
                    <h2 className="fw-bold">Trademark Application</h2>
                    <p className="fs-5">
                        Please review and approve the attached APPLICATION that was prepared by the
                        legal expert of as per your originally submitted trademark information in
                        STEP I. There could be some modifications that our team has found necessary
                        to help in the ultimate success of your APPLICATION.
                    </p>
                    <label className="mt-4">Trademark Application Form</label>
                    <Row className="d-flex mb-2 ">
                        {trademarkDetails?.pOAApplication?.documents.map((el, idx) => {
                            if (el?.documentType === "Application") {
                                return (
                                    <div key={idx}>
                                        <TrademarkDetail
                                            documentData={el?.media}
                                            documentViewerHandler={documentViewerHandler}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </Row>

                    <FormGroup>
                        <div>
                            <input
                                className="form-check-input"
                                style={{ width: "20px", height: "20px" }}
                                type="checkbox"
                                id="acceptTrademarkApplication"
                                name="acceptTrademarkApplication"
                                onChange={formik.handleChange}
                                checked={formik.values.acceptTrademarkApplication}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="acceptTrademarkApplication"
                                style={{
                                    fontSize: "16px",
                                    marginLeft: "10px",
                                    marginTop: "0.125rem"
                                }}
                            >
                                Accept Trademark Application
                            </label>
                        </div>
                        {formik.touched.acceptTrademarkApplication &&
                        formik.errors.acceptTrademarkApplication ? (
                            <span className="error">
                                {formik.errors.acceptTrademarkApplication}
                            </span>
                        ) : null}
                    </FormGroup>
                </FormGroup>
                <FormGroup>
                    <h2 className="fw-bold">Power of Attorney Form</h2>
                    <p className="fs-5">
                        {" "}
                        Please download the Power of Attorney form that was prepared by the Legal
                        Experts of <img src={shortLogo} alt="logo" width={80} height={18} /> as per
                        your originally submitted trademark information in step 1. Please review and
                        follow the execution instructions in page 02 of the Power of Attorney form,
                        then upload the executed Power of Attorney.
                    </p>
                    <Row>
                        {trademarkDetails?.pOAApplication?.legalisation && (
                            <Col md={3}>
                                <ImageUpload
                                    inputId={0}
                                    onChange={(e) =>
                                        handleFileChange(
                                            e,
                                            setPOAImage,
                                            documentTypePayload.TRADEMARK_POWER_ATTORNEY
                                        )
                                    }
                                    fileName={POAImage?.fileName}
                                    imageError={POAImage?.imageError}
                                    removeImageHandler={() => removeImage(setPOAImage)}
                                    errorType={
                                        errorType === 2 || errorType === 3
                                            ? fieldRequirementMessage.UPLOAD_FILE
                                            : ""
                                    }
                                />
                            </Col>
                        )}
                    </Row>

                    <label className="mt-4">Power of Attorney Form</label>
                    <Row className="d-flex mb-2">
                        {trademarkDetails?.pOAApplication?.documents
                            ?.filter((el) => el?.documentType === DOCUMENT_TYPES.PowerOfAttorney)
                            ?.map((el, idx) => {
                                return (
                                    <div key={idx}>
                                        <TrademarkDetail
                                            isDocumentSigned={eSign}
                                            documentData={el?.media}
                                            documentViewerHandler={documentViewerHandler}
                                            envelopeId={el?.envelopeId}
                                            documentType={el?.documentType}
                                            setESignStatus={setESign}
                                            documentID={el?.id}
                                            isLegalisation={Boolean(
                                                trademarkDetails?.pOAApplication?.legalisation
                                            )}
                                        />
                                    </div>
                                );
                            })}
                    </Row>
                    {Boolean(trademarkDetails?.pOAApplication?.legalisation) ? null : (
                        <p style={{ fontStyle: "italic", color: "#555", marginTop: "10px" }}>
                            If you have a problem signing the POA, please contact us to help you
                            with this step.
                        </p>
                    )}
                </FormGroup>
                <FormGroup>
                    <input
                        checked={isChecked} // Bind the `checked` property to the state
                        type="checkbox"
                        id="flexCheckChecked"
                        onChange={handleCheckboxChange}
                        className="form-check-input"
                        style={{ width: "20px", height: "20px" }}
                    />
                    <label
                        class="form-check-label"
                        for="flexCheckChecked"
                        style={{ fontSize: "16px", marginLeft: "10px" }}
                    >
                        I agree to the
                    </label>
                    <button
                        className="bg-transparent border-0 bold "
                        style={{ fontSize: "16px" }}
                        type="button"
                        onClick={handleTermsConditions}
                    >
                        Terms & Conditions
                    </button>

                    {showError && (
                        <div className="mt-2">
                            <span className="error">
                                ⚠️ Please check this box if you want to proceed.
                            </span>
                        </div>
                    )}
                </FormGroup>

                <Row className="btn-row d-flex justify-content-around mt-4 mb-5">
                    <CustomButton
                        disabled={!eSign && !isLegalisation}
                        type="submit"
                        color="primary"
                        title="Continue"
                        loading={isLoading}
                        className="w-40 login-btn-typo continue-btn"
                    />
                    <CustomButton
                        disabled={isLoading}
                        type="button"
                        color="Secondary"
                        title="Cancel"
                        onClick={(e) => handleCancelTrademarkRegistration(e)}
                        className="w-40 cancel-btn"
                    />
                </Row>
            </Form>
            <DocumentViewerModal
                modalActivator={isPdfViewerModalActive}
                modalCloseHandler={pdfViewerModalCloseHandler}
                modalData={pdfDataLink}
                isDataFetching={false}
            />
            <CustomModal
                isOpen={isModalOpen}
                data={
                    <div className="p-4 modal-detail">
                        <h2 className="">Important Note</h2>
                        <div className="my-4">
                            <h6 className="text-primary">Payments</h6>
                            <ul>
                                <li>
                                    Payment shall be made through VISA/Mastercard/American
                                    Express/Apple Pay/ MADA
                                </li>
                                <li>Payment is not refundable under any circumstances.</li>
                                <li>
                                    {" "}
                                    Submission of{" "}
                                    <span className="text-primary">
                                        <b>STEP 2</b>
                                    </span>{" "}
                                    will not go through until payment is received by{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />
                                    <span className="text-primary">Web Platform</span>
                                </li>
                            </ul>
                            <h6 className="text-primary">Submission</h6>
                            <ul>
                                <li>
                                    {" "}
                                    <b>Examination of Application:</b>
                                    After accepting the Application Form and provides the required
                                    Power of Attorney, your application will go to the related
                                    national trademark office of the selected country
                                </li>
                                <li>
                                    {" "}
                                    <b>Time of Examination:</b> It takes from 1 to 6 months for the
                                    related national trademark office to issue a decision on your
                                    application. If the examination takes more than this time,{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} /> is not
                                    responsible for this delay. You must regularly monitor the
                                    notices of your{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    account until the examination result is issued. You may also
                                    follow us on the chatting box for any help .
                                </li>
                                <li>
                                    {" "}
                                    <b>Acceptance of Application:</b> If the national trademark
                                    office accepts your application with a condition, you hereby
                                    give <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    the full authority to accept this condition even though it is
                                    different from prep the Application draft that you have accepted
                                    in this step. After{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    accepts the condition, you will successfully go to
                                    <span className="text-primary">STEP 3</span> .
                                </li>
                                <li>
                                    {" "}
                                    <b>Rejection of Application:</b> If the national trademark
                                    office rejects your application, the application will fail, and
                                    shall receive an explanation for the rejection of your
                                    APPLICATION. We advise you then to file a new request taking
                                    into consideration the reasons for the rejection.
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex justify-content-evenly align-items-center">
                            <CustomButton
                                color="primary"
                                title="Continue"
                                className="bv-btn-sm mb-3"
                                onClick={() => {
                                    setIsPaymentCardSelected(true);
                                    setIsModalOpen(false);
                                }}
                            />
                            <CustomButton
                                color=""
                                title="Cancel"
                                className="bv-btn-sm mb-3 border"
                                onClick={() => setIsModalOpen(false)}
                            />
                        </div>
                    </div>
                }
            />

            <PaymentModal
                isOpen={isPaymentCardSelected}
                amount={trademarkDetails?.pOAApplication?.amount || 0}
                selectedCard={selectedCard}
                onCardSelect={handleCardSelect}
                isFillingLoader={isFillingLoader}
                onContinue={tradeFillingHandler}
                onCancel={() => setIsPaymentCardSelected(false)}
            />
            <CustomModal
                isOpen={isPaymentMethodModal}
                data={
                    <div className="p-4 payment-mathod-modal">
                        <div className="d-flex align-items-center">
                            <button
                                className="border-0  p-0  bg-white "
                                onClick={() => {
                                    setIsPaymentCardSelected(true);
                                    setIsPaymentMethodModal(false);
                                }}
                            >
                                <LeftArrowIcon />
                            </button>

                            <h2 className="m-0 px-2">Payment</h2>
                            <br />
                        </div>

                        <h3 className="text-center">
                            Amount: <strong>$ {trademarkDetails?.pOAApplication?.amount}</strong>
                        </h3>
                        <div className="iframe-container">
                            <TrademarkPaymentModal
                                paymentId={paymentId}
                                selectedCard={selectedCard}
                            />
                        </div>
                    </div>
                }
            />
            <CustomModal
                isOpen={isTermConditionModal}
                size={"lg"}
                data={
                    <div className="p-4 modal-detail">
                        <h2 className="text-center">Terms & Conditions</h2>
                        <div className="my-4">
                            <ul>
                                <li>
                                    <b>Duration & Termination: </b>This service STARTS after
                                    receiving the payment of{" "}
                                    <span className="text-primary">STEP 2</span> and ENDS for
                                    receiving APPLICATION FAILURE reports or the the non payment on
                                    time during the following{" "}
                                    <span className="text-primary">STEP 3,4</span>
                                </li>
                                <li>
                                    {" "}
                                    <b>Limitation of Liability:</b>{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />
                                    <span className="text-primary"> Web Platform </span>is not
                                    liable for any delay of processing the APPLICATION by the
                                    national trademark office, the delay as a result of war, natural
                                    disaster, or any other force majeure matters.
                                </li>
                                <li>
                                    <b>Acceptance of the Use of Application:</b> By continuing the
                                    use of <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    <span className="text-primary">Web Platform </span> the
                                    applicant explicitly accepts all the terms and conditions
                                    herewith otherwise the applicant should STOP using{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    <span className="text-primary">Web Platform</span> .
                                </li>
                                <li>
                                    <b>Monitoring Notice:</b> The Applicant is required to monitor
                                    his application in a daily basis for any development or any
                                    chatting messages sent from our Team. The User is responsible
                                    for checking the received messages, whether through the
                                    application or the web, because these messages and alerts are
                                    specific to maintaining the continuity of the Application. The
                                    chat box and received alerts must be checked continuously, and
                                    the requirements received must be fulfilled. Any abandonment of
                                    the messages received in the chat box is the
                                    user’s responsibility. Deadlines and important notices will be
                                    sent over the chatting box at every stage.
                                </li>
                                <li>
                                    <b> Failure of Application: </b> Application may fail and not go
                                    through in each of the{" "}
                                    <span className="text-primary">STEPs 1, 2, 3 & 4</span>. The
                                    applicant accepts and agrees unconditionally that they will not
                                    request any refund for any payment that has been made before the
                                    APPLICATION's failure.
                                </li>
                                <li>
                                    <b>Limitation of Liability: </b>
                                    <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    <span className="text-primary">Web Platform</span> is operating
                                    this application as per the applicant's instruction and
                                    authorization to perform any modification necessary to maintain
                                    the application alive. Therefore, the applicant must confirm
                                    that <img src={shortLogo} alt="logo" width={80} height={18} />
                                    <span className="text-primary">Web Platform</span> is free of
                                    any direct or indirect damages or loss caused by the application
                                    as a result of the following:
                                    <ul>
                                        <li>Failure of the application</li>
                                        <li>Delay of the application</li>
                                        <li>Modification of the application</li>
                                        <li>
                                            Infringement of the application of any Third-party mark
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Governing Law and Settlement of Disputes:</b> The operation
                                    of this application is totally based on the trust, authority,
                                    and power given by the applicant to{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    <span className="text-primary">Web Platform </span> to do its
                                    best efforts for the registration of the applicant's mark. The
                                    applicant has provided a full limitation of liability to{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />
                                    <span className="text-primary">Web Platform</span>. The
                                    applicant has the right to abandon the application at any stage,
                                    therefore no dispute between the parties could ever occur. Both
                                    applicant and{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} /> agree
                                    on this non-dispute condition and that neither party in any
                                    circumstances will sue the other party, take any legal action,
                                    file any case against them under any applicable law or in any
                                    applicable jurisdiction of any country.
                                </li>
                                <li>
                                    <b>Confidentiality:</b> The information provided by the
                                    applicant is subject to confidentiality and will not be used
                                    except for the purpose of registering the client's mark.
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex justify-content-evenly align-items-center">
                            <CustomButton
                                color="primary"
                                title="Done"
                                className="bv-btn-sm mb-3"
                                onClick={() => {
                                    setIsTermConditionModal(false);
                                }}
                            />
                        </div>
                    </div>
                }
            />
            <AlertModal
                isOpen={isSuccessModal}
                title={"Request Successful!"}
                icon={<SuccessIcon />}
                content={"The case will be reviewed soon. We will notify you of the next steps."}
                btn_content={"Done"}
                isClose={() => {
                    history.push(CUSTOMER_ROUTES.TRADEMARK);
                }}
            />
            <ConfirmationModal
                isOpen={isTrademarkCancelled}
                content={"Are you sure want to cancel the process?"}
                btn_content={"Yes"}
                onYes={() => {
                    history.push(CUSTOMER_ROUTES.TRADEMARK);
                }}
                onNo={handleCancel}
            />
        </React.Fragment>
    );
};

export default TrademarkSignatureForm;
