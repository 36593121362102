import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Col, Row, Container } from "reactstrap";
import { useHistory } from "react-router-dom";

import { METATAGS } from "../../../constants";

import { GetTrademarkById } from "../../../api/api.service";
import { Loader } from "components/Loader";
import TrademarkSubmissionForm from "components/customer/trademark/TrademarkSubmissionForm";
import TrademarkSignatureForm from "components/customer/trademark/TrademarkSignatureForm";
import TrademarkPublicationForm from "components/customer/trademark/TrademarkPublicationForm";

import CheckIcon from "assets/images/customer/svg/CheckIcon";
import TrademarkTMCRForm from "components/customer/trademark/TrademarkTMCRForm";
import TrademarkRegistrationForm from "components/customer/trademark/TrademarkRegistrationForm";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { CustomModal } from "components/CustomModal";
import { CustomButton } from "components/CustomButton";
import SuccessIcon from "assets/images/customer/svg/SuccessIcon";
import { CUSTOMER_ROUTES } from "helpers/routeHelpers";
import { CASE_STATUS } from "constants/CaseStatus";
import { RESPONSE_STATUS } from "constants/ResponseStatus";

const AddTrademark = () => {
    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [trademarkDetail, setTrademarkDetail] = useState();
    const [isRefetchingRequired, setIsRefetchingRequired] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const isStepComplete = location.state?.data?.status;

    // refetch steps API handler
    const refetchStepsAPIHandler = () => {
        setIsRefetchingRequired(!isRefetchingRequired);
    };

    const trademarkId = Number(params.id);
    useEffect(async () => {
        if (trademarkId) {
            try {
                setIsFetchingData(true);
                let res = await GetTrademarkById(trademarkId);
                setTrademarkDetail(res);
            } finally {
                setIsFetchingData(false);
            }
        }
    }, [isRefetchingRequired, trademarkId, location?.state?.isSameId]);

    // Define the variable
    const isTrademarkStepPendingAndComplete =
        trademarkDetail?.trademarkStepsStatus == RESPONSE_STATUS.Pending &&
        isStepComplete == CASE_STATUS.Completed;

    // Open the modal when the condition is true
    useEffect(() => {
        if (isTrademarkStepPendingAndComplete) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [isTrademarkStepPendingAndComplete]); // Add the variable to the dependency array

    switch (trademarkDetail?.trademarkStep) {
        case "FILING":
            trademarkDetail.form = <TrademarkSubmissionForm />;
            trademarkDetail.stepNumber = 1;
            break;
        case "POAAPPLICATION":
            trademarkDetail.form = <TrademarkSignatureForm />;
            trademarkDetail.stepNumber = 2;
            break;
        case "PUBLICATION":
            trademarkDetail.form = <TrademarkPublicationForm />;
            trademarkDetail.stepNumber = 3;
            break;
        case "REGISTRATION":
            trademarkDetail.form = <TrademarkRegistrationForm />;
            trademarkDetail.stepNumber = 4;
            break;
        case "TMCRDOWNLOAD":
            trademarkDetail.form = <TrademarkTMCRForm />;
            trademarkDetail.stepNumber = 5;
            break;
    }

    const stepTabs = [
        {
            key: 1,
            title: "Step 01"
        },
        {
            key: 2,
            title: "Step 02"
        },
        {
            key: 3,
            title: "Step 03"
        },
        {
            key: 4,
            title: "Step 04"
        },
        {
            key: 5,
            title: "Step 05"
        }
    ];

    // Close the modal when "Done" button is clicked
    const handleClose = () => {
        history.push(CUSTOMER_ROUTES.TRADEMARK);
    };

    return (
        <React.Fragment>
            <div className="dashboard-container add-trademark-container">
                <MetaTags>
                    <title>{METATAGS.ADD_TRADEMARK}</title>
                </MetaTags>
                <Container fluid className="mt-5">
                    {isFetchingData ? (
                        <>
                            <Loader />
                        </>
                    ) : !isFetchingData && trademarkDetail ? (
                        <>
                            <Row>
                                <Col className="form-tabs">
                                    {trademarkDetail && stepTabs
                                        ? stepTabs?.map((item) => {
                                              if (trademarkDetail?.stepNumber > item.key) {
                                                  return (
                                                      <div className={`step`}>
                                                          <CheckIcon />
                                                      </div>
                                                  );
                                              } else if (trademarkDetail.stepNumber < item.key) {
                                                  return <div className={`step`}>{item.title}</div>;
                                              } else if (trademarkDetail.stepNumber == item.key) {
                                                  return (
                                                      <div className={`step active`}>
                                                          {item.title}
                                                      </div>
                                                  );
                                              } else {
                                                  <div className={`step`}>{item.title}</div>;
                                              }
                                          })
                                        : stepTabs &&
                                          stepTabs.map((item, idx) => {
                                              return (
                                                  <div
                                                      className={`step ${idx == 0 ? "active" : ""}`}
                                                  >
                                                      {item.title}
                                                  </div>
                                              );
                                          })}
                                </Col>
                            </Row>
                            {trademarkDetail ? (
                                <Row className="mt-4">
                                    <Col md={12}>{trademarkDetail.form}</Col>
                                </Row>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <Row>
                                <Col className="form-tabs">
                                    {stepTabs.map((item, idx) => {
                                        return (
                                            <div className={`step ${idx == 0 ? "active" : ""}`}>
                                                {item.title}
                                            </div>
                                        );
                                    })}
                                </Col>
                            </Row>
                            <TrademarkSubmissionForm />
                        </>
                    )}
                </Container>
            </div>

            <CustomModal
                isOpen={isOpen}
                className={""}
                data={
                    <div className="p-4 trademark-status-modal">
                        <div>
                            <h5>{"Waiting For Response"}</h5>
                        </div>
                        <div className="py-2">
                            <SuccessIcon />
                        </div>
                        <div>
                            <p>
                                <div className="text-container">
                                    {
                                        "The case is in progress and will be reviewed soon. We will notify you of the next steps."
                                    }
                                </div>
                            </p>
                        </div>
                        <CustomButton
                            color="primary"
                            title="Done"
                            onClick={handleClose}
                            className="w-45 bv-btn-sm mb-3"
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default AddTrademark;
