import { combineReducers } from "redux";
import { SIGN_OUT } from "../actions";
import Layout from "../layout/reducer";
import Dashboard from "../dashboard/reducer";
import DashboardSaas from "../dashboard-saas/reducer";
import authReducer from "./authReducer";
import root from "./rootReducer";
import formDataReducer from "store/actions/trademark/reducers/reducers";

const appReducer = combineReducers({
    root,
    Layout,
    Dashboard,
    DashboardSaas,
    userAuth: authReducer,
    formData: formDataReducer
});

const rootReducer = (state, action) => {
    if (action.type === SIGN_OUT) {
        // const { Layout, deviceId } = state;
        // state = { Layout, deviceId };
        const { Layout } = state;
        state = { Layout };
    }

    return appReducer(state, action);
};

export default rootReducer;
